import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../Slices/UserSlice";
import {
  UserPlus2,
  UserCog,
} from "lucide-react";

const Header = (props) => {
  const user = useSelector(selectUser);

  return (
    <nav>
      {user.isLogged ? (
        <>
          <Link to="https://api.vinsnaturels.fr">
            <UserCog size={38} />
          </Link>
        </>
      ) : (
        <>
          <Link to="https://api.vinsnaturels.fr">
            <UserPlus2  size={38} />
          </Link>
        </>
      )}
    </nav>
  );
};

export default Header;

/*
          {user.isLogged ? (
            <><Link to="https://www.vinsnaturels.fr"><UserCog className="text_vert"  size={38} /></Link></>
          ) :
            <><Link to="https://www.google.fr"><UserPlus2 className="text_vert"  size={38} /></Link></>
            }
          
*/
