import axios from "axios";
import { config } from "../config"
// recupérer le token avec getItem
const token = window.localStorage.getItem('VN_token')



export function checkToken(token){
  //console.log("checkToken : ", token)    
  return axios.get(`${config.api_url}/api/v1/checkToken`, {headers: {"x-access-token": token}})
      .then((res)=>{
          return res.data
      })
      .catch((err)=>{
          return err
      })
} 
